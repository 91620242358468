.Card {
	min-width: 100%;
	min-height: 100%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
	transition: 0.3s;
}

.Card:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.8);
	cursor: pointer;
}

.Imageless {
	padding: 20%;
	display: flex;
	align-items: center;
}

.BrandName {
	font-weight: bold;
}

.CardImage {
	margin-top: 10%;
	max-width: 65%;
	max-height: 65%;
}
