.contact-container {
	padding-top: 8%;
}

.contact-header {
	font-size: 55px;
}

.contact-body {
	font-size: 25px;
}
