.ContactForm {
    max-width: 500px;
    margin: auto;
}

.contactInput {
    margin-top: 5%;
}

.FormWrap {
    margin-top: 5%;

}

.contactReply {
    color: white;
    margin-top: 5%;
    text-shadow: 1px 1px #000000;
}