.Footer {
    background-color: rgba(255, 255, 255, 0.322);
    color: black;
    bottom: 0px;
    left: 0px;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 0;


}

.FooterWrap {
    margin-top: auto;
    margin-bottom: 0;
}