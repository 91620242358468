.App {
	text-align: center;

	background-image: url('../public/Images/background.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: auto;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
