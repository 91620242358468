.searchbar-input {
	border-radius: 10px;
	padding-left: 3%;
	width: 100%;
}
.searchbar-container {
	width: 30%;
	position: relative;
}
.searchbar-results {
	width: 100%;
	margin-top: 2px;
	position: absolute;
	background-color: white;
	border-radius: 10px;
	z-index: 1000;
	border: 2px black solid;
}

.searchbar-box {
	border-bottom: 1px black solid;
	width: 90%;
	margin: auto;
	padding: 3% 1%;
}
.searchbar-box:hover {
	background-color: gray;
	cursor: pointer;
}
.result {
	color: black;
	margin-bottom: 0;
}
