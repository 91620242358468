.under-construction-body {
	padding-top: 10%;
	width: 30%;
	align-self: center;
}

.under-construction-text {
	font-size: 30px;
	font-weight: bold;
}

.under-construction-image {
	width: 100%;
}
