.subTitle {
	color: white;
	font-weight: 350;
	padding: 10px;
	margin-bottom: 3%;
	margin-top: 3%;
	background-color: rgba(63, 63, 204, 0.233);
}

.title {
	color: white;
	font-weight: 500;
	padding: 5px;

	margin-top: 2%;
}

.clickToContact {
	cursor: pointer;
	display: inline;
	color: white;
	font-weight: 450;
}

.GridSetting {
	display: grid;
	grid-template-columns: auto auto auto auto;
	row-gap: 4%;
	column-gap: 0.5%;
}
