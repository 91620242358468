.tables-container {
	margin-top: 5%;
	width: 80%;
	align-self: center;
}

.individual-table-container {
	padding: 3%;
}

.table-container-header {
	font-size: 200%;
}

td {
	color: white;
}

th {
	color: white;
	font-weight: bold;
}

table {
	border-collapse: collapse;
	border: 2px gray inset;
	border-spacing: 0;
}

.table-unit {
	border-collapse: collapse;
	border: 2px rgb(37, 37, 37) inset;
	border-spacing: 0;
}
