.logo {
    height: 40px;
    margin-right: 10px;
}

.navText {
    font-family: Helvetica, sans-serif;
    font-size: large;
    font-weight: 550;
    color: white !important;
    text-shadow: 1px 1px #000000;
}

.navText:hover {
    cursor: pointer;
    text-underline-offset: 50%;
    text-decoration: underline;
}

.navBrand {
    font-family: Helvetica, sans-serif;
    font-size: larger;
    font-weight: bold;
    color: white !important;
    text-shadow: 1px 1px #000000;
}

.NavWrap {
    padding-top: 1%;
}

.me-auto {
    width: 100%;
    white-space: nowrap;
    justify-content: space-evenly
}

.dropdown-menu {
    background-color: rgba(63, 63, 204, 0.322) !important;
    border: none !important;

}

.dropdown-item {
    color: white !important;
    background-color: rgba(63, 63, 204, 0.432) !important;
    font-weight: 100 !important;

}

.dropdown-item:hover {
    font-size: large;

}

#basic-dropdown {
    background-color: transparent;
    border: none;
    text-shadow: 1px 1px #000000;
    font-family: Helvetica, sans-serif;
    font-size: large;
    font-weight: 550;
    padding: 0;
}

.d-flex {
    min-width: 25%;

}