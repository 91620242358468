.page-title {
	padding-top: 5%;
	font-size: 30px;
}

.page-body {
	width: 100%;
	justify-content: space-between;
	align-content: flex-start;
	padding-top: 5%;
}

.description-column {
	display: inline-block;

	font-size: 20px;
	vertical-align: top;
}
.description-column-video {
	width: 70%;
}
.description-column-no-video {
	width: 100%;
}

.video-column {
	display: inline-block;
	width: 25%;
	height: 100%;
	vertical-align: top;
}

.instruction-container {
	vertical-align: middle;
	display: inline-block;
}
.instruction-container-image {
	width: 60%;
}
.instruction-container-no-image {
	width: 90%;
}

.image-container {
	vertical-align: middle;
	display: inline-block;
	width: 30%;
}

.instruction-image {
	width: 100%;
	transition: transform 0.5s;
}

.instruction-entry {
	padding: 1% 0;
}

p {
	color: white;
}

.new-p {
	text-shadow: -1px -1px 0 rgb(59, 59, 59), 1px -1px 0 rgb(59, 59, 59),
		-1px 1px 0 rgb(59, 59, 59), 1px 1px 0 rgb(59, 59, 59);
	color: white;
	font-weight: bold;
}
.instruction-image:hover {
	transform: scale(1.3);
}

.code {
	width: 100%;
	background-color: gainsboro;
	border-radius: 20px;
	border: 3px #676767 solid;
	padding-left: 2%;
	display: inline-block;
	vertical-align: middle;
}

.code-text {
	color: black;
	margin: 0;
	padding: 1%;
}
